.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.root {
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.line {
  color: #6F7070;
  width: 18%;
  margin-top: -2%;
}
.main {
  background-image: url(./images/home.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}


.social {
  width: 70px;
height: 70px;
background: white;
border-radius: 31px;
background: #fff;
box-shadow: 0px 3px 6px rgba(209, 209, 249, 0.16);
border: none;

}

.downloadButton {
  width: 311px;
  height: 46px;
  background: #7670fa;
  border-radius: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px
}